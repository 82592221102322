import { useState } from "react";
import python from "../assets/python.svg";
import aws from "../assets/aws.svg";
import mongodb from "../assets/mongodb.svg";
import typescript from "../assets/typescript.svg";
import html5 from "../assets/html5.svg";
import css from "../assets/css.svg";
import nextjs from "../assets/nextjs.svg";
import react from "../assets/react.svg";
import vite from "../assets/vite.svg";
import django from "../assets/django.svg";
import firebase from "../assets/firebase.svg";
import nodejs from "../assets/nodejs.svg";

export const Services = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="services section" id="services">
      <h2 className="section_title">Skills</h2>
      <span className="section_subtitle">Technologies and Expertise</span>
      <div className="services_container container grid">
        {/*==================== SERVICES 1 ====================*/}
        <div className="services_content">
          <div className="services_card">
            <img src={python} alt="python logo" className="services_image" />
            <img
              src={django}
              alt="django logo"
              className="services_image-left"
            ></img>
            <h3 className="services_title">Python & Django</h3>
            <p className="services_description">
              Experienced in building web apps with Django and Flask, focusing
              on clean architecture and RESTful APIs. Skilled in data analysis
              with Pandas and NumPy, and deploying scalable solutions using
              Docker, AWS, and Heroku.
            </p>
          </div>
        </div>

        {/*==================== SERVICES 2 ====================*/}
        <div className="services_content">
          <div className="services_card">
            <img src={nodejs} alt="nodejs logo" className="services_image" />
            <img
              src={mongodb}
              alt="mongodb logo"
              className="services_image-left"
            />
            <h3 className="services_title">Node.js & MongoDB</h3>
            <p className="services_description">
              Skilled in backend development with Node.js and MongoDB.
              Proficient in creating RESTful APIs, integrating PostgreSQL, and
              deploying scalable applications on AWS, Heroku, and Vercel.
            </p>
          </div>
        </div>
        {/*==================== SERVICES 3 ====================*/}
        <div className="services_content">
          <div className="services_card">
            <img
              src={typescript}
              alt="typescript logo"
              className="services_image"
            />
            <img
              src={nextjs}
              alt="nextjs logo"
              className="services_image-left"
            />
            <h3 className="services_title">TypeScript & Next.js</h3>
            <p className="services_description">
              Experienced in building web apps with TypeScript and Next.js,
              leveraging SSR, SSG, and API routes. Proficient in deploying
              optimized, production-ready solutions on Vercel with a focus on
              performance and SEO.
            </p>
          </div>
        </div>

        {/*==================== SERVICES 3 ====================*/}
        <div className="services_content">
          <div className="services_card">
            <img src={html5} alt="html5 logo" className="services_image" />
            <img src={css} alt="css logo" className="services_image-left" />
            <h3 className="services_title">HTML & CSS</h3>
            <p className="services_description">
              Skilled in crafting responsive web interfaces with HTML5 and CSS3.
              Proficient in Flexbox, Grid, and modern design principles for
              intuitive layouts and seamless user experiences.
            </p>
          </div>
        </div>

        {/*==================== SERVICES 3 ====================*/}
        <div className="services_content">
          <div className="services_card">
            <img src={react} alt="react logo" className="services_image" />
            <img src={vite} alt="vite logo" className="services_image-left" />
            <h3 className="services_title">React & Vite</h3>
            <p className="services_description">
              Proficient in developing web apps with React and Vite. Skilled in
              creating reusable components with React Hooks, managing state with
              Context API and Redux, and optimizing performance through CSR and
              code-splitting.
            </p>
          </div>
        </div>

        {/*==================== SERVICES 3 ====================*/}
        <div className="services_content">
          <div className="services_card">
            <img src={aws} alt="aws logo" className="services_image" />
            <img
              src={firebase}
              alt="firebase logo"
              className="services_image-left"
            />
            <h3 className="services_title">AWS & Firebase</h3>
            <p className="services_description">
              Experienced in deploying and scaling full-stack applications with
              AWS (EC2, S3, Lambda) for optimal performance. Skilled in using
              Firebase for real-time databases, authentication, and cloud
              functions to build robust, scalable web solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
